import { defineStore } from 'pinia'

export const useInternal = defineStore('internal', {
  state: () => ({
    menu: false,
    content: false,
    loading: false,
    id: null
  }),
  actions: {
    useLoading(x) {
      this.loading = x
    },
    useContent(x) {
      this.content = x
    },
    useMenu(x) {
      this.menu = x
    },
    useId(x) {
      this.id = x
    }
  }
})
